<div class="page">
    <div class="page-padding" style="">
        <div class="row" style="height: inherit">
            <div class="col-12 col-lg-8">
                <div class="page-header-custom">
                    <svg-icon class="is-cursor" [routerLink]="'/certificate'" src="assets/svg/back.svg"></svg-icon>
                    <h1 class="page-title">{{getTranslate(certificate.type)}}</h1>
                    <div class="badge badge-custom px-4 py-1"
                         [ngClass]="{
                            'badge-gray' : (certificate.status === 'received'),
                            'badge-warning' : (certificate.status === 'review'),
                            'badge-success' : (certificate.status === 'approved'),
                            'badge-danger' : (certificate.status === 'reproved')
                        }">{{getTranslate(certificate.status)}}
                    </div>
                </div>
                <div class="page-card secondary d-flex flex-column">
                    <div class=" d-flex justify-content-between align-items-center">
                        <h2 class="page-title tertiary">{{'sapcertificate'|translate}}</h2>
                        <label class="switch">
                            <input type="checkbox" (click)="changeExport(certificate)" name="checkbox"
                                   [(ngModel)]="certificate.exportSap" [disabled]="certificate.exportSap">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="row px-4">
                    <div class="col">
                        <div class="d-flex flex-wrap mt-1" style="column-gap: 15px">
                            <div class="flex-grow-1">
                                <p class="page-lbl text-capitalize">{{'nome'|translate}}</p>
                                <p class="page-value">{{certificate.ownerBy?.name}}</p>
                            </div>
                            <div class="flex-grow-1">
                                <p class="page-lbl">{{'recolaborador'|translate}}</p>
                                <p class="page-value">{{certificate.ownerBy?.re}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-card secondary">
                    <h2 class="page-title tertiary text-capitalize">{{'atestado'|translate}}</h2>
                    <div class="d-flex flex-wrap" style="column-gap: 15px">
                        <div class="flex-grow-1" *ngIf="certificate.type !=='consult'">
                            <p class="page-lbl">  {{'dataemissao'|translate}}</p>
                            <p class="page-value">{{certificate.emissionDate|date:'dd/MM/yyyy'}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="certificate.type ==='escort' || certificate.type ==='consult' ">
                            <p class="page-lbl">  {{'tipoatendimento'|translate}}</p>
                            <p class="page-value">{{certificate.appointmentType | translate}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="certificate.type ==='escort'">
                            <p class="page-lbl">  {{'escort'|translate}}</p>
                            <p class="page-value">{{certificate.certificateOwner| translate}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="certificate.type ==='consult'">
                            <p class="page-lbl">  {{'criacao'|translate}}</p>
                            <p class="page-value">{{certificate.createdAt|date:'dd/MM/yyyy'}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="certificate.type ==='maternity'">
                            <p class="page-lbl">Data de afastamento</p>
                            <p class="page-value">{{certificate.timeOffStartDate |date:'dd/MM/yyyy'}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="!isNullOrUndefined(certificate.crm)">
                            <p class="page-lbl">CRM/CRO</p>
                            <p class="page-value">{{certificate.crm}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="!isNullOrUndefined(certificate.uf)">
                            <p class="page-lbl">UF</p>
                            <p class="page-value">{{certificate.uf}}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-4" style="column-gap: 15px;">
                        <div *ngIf="!isNullOrUndefined(certificate.appointmentStart)" class="flex-grow-1">
                            <p class="page-lbl lbl-lg">{{'saida'|translate}}</p>
                            <p class="page-value">{{certificate.appointmentStart |date:'dd/MM/yyyy'}}</p>
                        </div>
                        <div *ngIf="!isNullOrUndefined(certificate.appointmentStart)" class="flex-grow-1">
                            <p class="page-lbl">&nbsp;</p>
                            <p class="page-value">{{certificate.appointmentStart|date:'HH:mm' : 'UTC'}}</p>
                        </div>
                        <div *ngIf="!isNullOrUndefined(certificate.appointmentEnd)" class="flex-grow-1">
                            <p class="page-lbl lbl-lg">{{'retorno'|translate}}</p>
                            <p class="page-value">{{certificate.appointmentEnd|date:'dd/MM/yyyy'}}</p>
                        </div>
                        <div *ngIf="!isNullOrUndefined(certificate.appointmentEnd)" class="flex-grow-1">
                            <p class="page-lbl">&nbsp;</p>
                            <p class="page-value">{{certificate.appointmentEnd|date:'HH:mm' : 'UTC'}}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-4">
                        <div class="flex-grow-1" *ngIf="!isNullOrUndefined(certificate.cid)">
                            <p class="page-lbl">CID</p>
                            <p class="page-value">{{certificate.cid}}</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="!isNullOrUndefined(certificate.timeOff)">
                            <p class="page-lbl">{{'tempoatendimento'|translate}}</p>
                            <p class="page-value">{{certificate.timeOff}} Dia(s)</p>
                        </div>
                        <div class="flex-grow-1" *ngIf="!isNullOrUndefined(certificate.appointmentDate)">
                            <p class="page-lbl">{{'dataatendimento'|translate}}</p>
                            <p class="page-value">{{certificate.appointmentDate|date:'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-4">
                        <div class="flex-grow-1" *ngIf="!isNullOrUndefined(certificate.observation)">
                            <p class="page-lbl">Observação</p>
                            <p class="page-value">{{certificate.observation}}</p>
                        </div>
                    </div>
                </div>
                <div class="page-card secondary" *ngIf="!isNullOrUndefined(certificate.image)">
                    <h2 class="page-title tertiary">{{'imagemretorno'|translate}}</h2>
                    <div class="row">
                        <div *ngFor="let img of certificate.image"
                             class="col-3 d-flex flex-column justify-content-center align-items-center mb-2"
                             style="gap: 15px">
                            <img class="page-upload img"
                                 src="{{getUrlCertificateImage(img.url)}}"
                                 alt="certificate"/>
                            <a href="{{img.url}}"
                               target="_blank" class="btn btn-primary text-white">
                                <svg-icon class="mr-2" src="assets/svg/download.svg"></svg-icon>
                                Download</a>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-lg-4">
                <div class="d-flex flex-column justify-content-between" style="height: 100%">
                    <div class="page-card tertiary flex-grow-1">
                        <h2 class="page-title flex-grow-0 mb-4">{{'comentarios'|translate}}</h2>
                        <div class="flex-grow-1" >
                            <ng-container *ngIf="isNullOrUndefined(certificate.comment)">
                                <div class="flex-grow-1">
                                    <div class="d-flex justify-content-center">
                                        <ng-lottie width="300px" height="initial" [options]="message"></ng-lottie>
                                    </div>
                                    <p class="page-lbl comments">{{'emcasodeduvidas'|translate}}</p>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="!isNullOrUndefined(certificate.comment)">
                                <div class="page-chat">
                                    <ng-container *ngFor="let i of certificate.comment">
                                        <div class="d-flex"
                                             [ngClass]="{'flex-row-reverse':i.createdBy.id == this.userId}"
                                             style="gap: 20px">

                                            <img *ngIf="!isNullOrUndefined(i.createdBy.photo)"
                                                 src="{{i.createdBy.photo}}"
                                                 alt="person"
                                                 class="page-person">

                                            <ng-lottie *ngIf="isNullOrUndefined(i.createdBy.photo)"
                                                       class="mb-3"
                                                       width="35px"
                                                       [options]="usersAnimation"></ng-lottie>

                                            <!--                                            <ng-lottie *ngIf="isNullOrUndefined(i.createdBy.photo)" class="mb-3"-->
                                            <!--                                                       width="120px" [options]="usersAnimation"></ng-lottie>-->
                                            <!--                                            -->
                                            <div class="bubbly"
                                                 [ngClass]="[ (i.createdBy.id != this.userId ? 'sb1' : 'sb2')]">
                                                <p class="bubbly-name">{{i.createdBy.name}}</p>
                                                <p class="bubbly-text">{{i.comment}}</p>
                                                <p class="bubbly-date">{{i.createdAt |date:'dd/MM'}}</p>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="position-relative" *ngIf="certificate.status != 'reproved' && certificate.status != 'approved'">
                            <textarea autosize minRows="1" [(ngModel)]="this.createCommentInput.comment" maxRows="6"
                                      class="message-text"
                                      placeholder="Escreva aqui"></textarea>
                            <svg-icon class="is-cursor" (click)="actionComments()" src="assets/svg/send.svg"></svg-icon>
                        </div>
                    </div>
                    <div *ngIf="certificate.status != 'reproved' && certificate.status != 'approved'">
                        <a *ngIf="certificate.status == 'received'" (click)="onRevision()"
                           class="btn btn-block btn-outline-primary"
                           style="margin-bottom: 21px;">Revisar</a>
                        <div class="d-flex flex-grow-1" style="column-gap: 31px;row-gap: 21px">
                            <a (click)="onJustification()"
                               class="btn btn-secondary flex-grow-1">{{'reprovar'|translate}}</a>
                            <a (click)="onApproved()" class="btn btn-primary flex-grow-1">{{'aprovar'|translate}}</a>
                        </div>
                    </div>
                    <div *ngIf="certificate.status == 'reproved'">
                        <div class="page-card secondary mb-0">
                            <h2 class="page-title tertiary">Justificativa da reprova</h2>
                            <p class="page-text">{{certificate.reproveJustification}}</p>
                            <div class="page-divider my-3">&nbsp;</div>
                            <div class="d-flex align-items-center" style="gap: 15px">
                                <img [src]="certificate.createdBy.photo?certificate.createdBy.photo:'assets/images/person-1.png'"
                                     alt="person"
                                     class="page-person">
                                <p class="page-text flex-grow-1">{{certificate.reprovedBy.name}}</p>
                                <p class="page-text">{{certificate.createdAt | date:'dd/MM'}}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalJustification" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document" style="max-width: 454px;">
        <div class="modal-content p-0">
            <div class="modal-header align-items-center bg-gray" style="min-height: 107px;">
                <h2 class="modal-title">{{'recusado'|translate}}</h2>
                <a data-dismiss="modal" class="modal_close" aria-label="Close">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="p-3 p-md-4">
                <p class="modal-lbl">{{'justifiquerecusa'|translate}}</p>
                <div class="input-material">
                    <textarea [(ngModel)]="reproveInput.reproveJustification" id="justification" rows="10"
                              class="form-control pt-0" name="type">
                    </textarea>
                    <label for="justification"></label>
                </div>
                <div class="d-flex flex-grow-1" style="gap: 30px;max-width: 394px;margin: 0 auto">
                    <a data-dismiss="modal" aria-label="Close"
                       class="btn flex-grow-1 btn-outline-primary">{{'cancelar'|translate}}</a>
                    <a (click)="onReprove()" class="btn flex-grow-1 btn-primary">{{'enviar'|translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

