import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';


declare var $: any;

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss']
})
export class CropImageComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() croppedImageResult = new EventEmitter<any>();
  @Input() cropAspectRatio: any = 4 / 3;
  @Input() cropResizeToWidth: any = 400;
  @Input() cropResizeToHeight: any = 300;
  @Input() cropId: any = 'modalCrop';

  cropCanvasRotation = 0;
  croppedImage: any;
  imageChangedEvent: any;
  croppedFile: any;
  ischeck: boolean;

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public translate: TranslateService,
              public loading: NgxSpinnerService) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
    $(() => {
      const el = $('#' + this.cropId);
      el.on('shown.bs.modal', () => {
        el.find('.description__photo--file').click();
      });
    });
  }

  ngAfterViewInit(): void {
    this.ischeck = true;
  }

  ngOnDestroy(): void {
    const elModalUser = $('#' + this.cropId);
    if (elModalUser[0]) {
      elModalUser.remove();
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const file = new File([base64ToFile(event.base64)],
        this.imageChangedEvent.target.files[0].name,
        {
          lastModified: this.imageChangedEvent.target.files[0].lastModified,
          type: this.imageChangedEvent.target.files[0].type
        }
    );
    this.croppedFile = [file];
  }

  fileChangeEvent(event: any): void {
    const files = event.target.files;

    if (files.length > 0) {
      if (files[0].type.toLowerCase().indexOf('pdf') >= 0) {
        console.log('enviou um pdf');
        console.log(files);
        const o = {thumb: '/assets/images/ico-pdf.png', file: files[0]};
        this.croppedImageResult.emit(o);
        setTimeout(() => {
          this.onReset();
          this.closeModal(this.cropId);
        }, 500);
        return;
      } else {
        if (files[0].type.toLowerCase().indexOf('jpg') === -1
            && files[0].type.toLowerCase().indexOf('png') === -1
            && files[0].type.toLowerCase().indexOf('jpeg') === -1) {
          super.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningExtensionPngJpg'), 'info');
          return;
        }
      }
    }
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onReset(): void {
    this.cropCanvasRotation = 0;
    this.croppedImage = null;
    this.imageChangedEvent = null;
    this.croppedFile = null;
  }

  onImageRotate(): void {
    this.cropCanvasRotation = this.cropCanvasRotation === 4 ? 0 : (this.cropCanvasRotation + 1);
  }

  actionIscheck() {
    this.ischeck = true;
  }

  onImageSave(): void {
    this.ischeck = false;
    const o = {thumb: this.croppedImage, file: this.croppedFile};
    this.croppedImageResult.emit(o);
    setTimeout(() => {
      this.onReset();
      this.closeModal(this.cropId);
      this.ischeck = true;
    }, 500);
  }
}
